import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/security-usecase.scss'
import ArrowRight from '../images/icons/arrow-right.svg'
import LinkImg from '../images/human-capital-usecase/bg-link.png'
import LockImg from '../images/human-capital-usecase/lock-img.png'
import LouvreImg from '../images/human-capital-usecase/bg-louvre.png'
import BookImg from '../images/human-capital-usecase/bg-book.png'
import { Link } from 'gatsby'

const SecurityUsecase = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="security-usecase py-5">
                <div className="hero-section">
                    <div className="h-100 d-flex align-items-end">
                        <div className="container px-5">
                            <div className="row mb-5 pb-5 px-4">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div style={{ borderBottom: '3px solid #E41700' }}></div>
                                        </div>
                                        <div className="col-md-11">
                                            <h1 className="font-weight-bold" style={{ lineHeight: '58px', fontSize: '3rem' }}><span className="text-red">Risk Management</span></h1>

                                            <p className="mb-0" style={{ fontSize: '1.5rem' }}>Exceed your organizational goals with world-class technology, seasoned advice & precise implementation.</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-1"></div>

                                <div className="col-md-4 my-auto">
                                    <p className="small mb-0" style={{ lineHeight: '22px' }}>From multinationals to small businesses; no matter the industry, market or geography; we will equip you with tools to build, sustain and expand your offerings to compete and dominate in the global market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5 pt-5">
                    <div className="my-md-5 py-md-5">
                        <h1 className="big-text text-center font-weight-bold mb-5 pb-5 mt-5 px-md-5 px-0">Here’s what we have <span className="text-red">done</span> for <span className="text-red">businesses</span> just like yours…</h1>
                    </div>
                </div>

                <div className="container my-5 py-5">

                    <div className="row">
                        <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={LinkImg} alt="background" />
                            </div>

                            <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">Network security and audit</span>Logistics</p>

                            <Link to="/network-security-audit"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>Reinforcing network security for a global conglomerate</h4></Link>

                            <Link to="/network-security-audit"><p className="cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                        </div>

                        <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={LockImg} alt="background" />
                            </div>

                            <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2"> Data protection</span>law</p>

                            <Link to="/data-protection-proctor-law"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>Helping a law firm protect their intellectual property</h4></Link>

                            <Link to="/data-protection-proctor-law"><p className="cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                        </div>

                        {/* <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={LinkImg} alt="background" />
                            </div>

                            <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Network security and audit</span>Delware Tech</p>

                            <h5 className="cursor black-link-text">Reinforcing network security for a global conglomerate.</h5>

                            <p className="cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                        </div> */}
                    </div>

                    {/* <div className="row mt-5">
                        <div className="col-md-8">
                            <div className="louvre-bg">
                                <img src={LouvreImg} alt="background" />
                            </div>

                            <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Managed services</span>The louvre</p>

                            <h5 className="cursor black-link-text">But I must explain to you how all this mistaken idea of denouncing.</h5>

                            <p className="cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                        </div>

                        <div className="col-md-4">
                            <div className="louvre-bg">
                                <img src={BookImg} alt="background" />
                            </div>

                            <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Data protection</span>Unilever</p>

                            <h5 className="cursor black-link-text">But I must explain to you how all this mistaken idea of denouncing.</h5>

                            <p className="cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                        </div>
                    </div> */}
                </div>
            </div>
        </Layout>
    )
}

export default SecurityUsecase
